import React from 'react'
import classNames from 'classnames'
import './style.css'

const Container = ({ className, children, element: Element, fill }) => {
  return (
    <Element
      className={classNames('container', className, {
        'container--fill': fill,
      })}
    >
      {children}
    </Element>
  )
}

Container.defaultProps = {
  element: 'div',
  className: null,
  fill: false,
}

export default Container
