import React from 'react'
import NavHeader from '../AhzNavHeader'
import ModalDeviceStore from '../ModalDeviceStore'
import Footer from '../Footer'

const Layout = ({
  downloadOpen,
  downloadClose,
  downloadModalOpened,
  children
}) => {

  return (
    <div className="main-page" id="inicio">
      <NavHeader
        onDownloadClick={downloadOpen}
      />

      {children}

      <div className="main-page__footer">
        <Footer />
      </div>
      
      <ModalDeviceStore opened={downloadModalOpened} onClose={downloadClose} />
    </div>
  )
}

export default Layout
