import React from 'react'
import StoreBadge from '../StoreBadge'
import Container from '../Container'
import { isIOS } from 'react-device-detect'
import { Logo, Typography, Icon } from '@ahazou/react-components'
import youtubeIcon from "../../images/youtube.png";
import tiktokIcon from "../../images/tiktok.png";
import './style.css'

const Footer = () => {
  return (
    <footer className="footer">

      <Container>
        <div className="footer__logo">
          <Logo />
        </div>
        <nav className="footer__nav">
          <ul className="footer__nav__list footer__nav__first_column">
            <li className="footer__nav__list__item">
              <Typography variant="displayS" className="footer__nav__list__item__title">conheça o Ahazou</Typography>
            </li>
            <li className="footer__nav__list__item">
              <a href="https://ahazou.com/#" title="Ir para o início da página" className="footer__nav__list__item__anchor">
                <Typography element="span" variant="buttonS">início</Typography>
              </a>
            </li>
            <li className="footer__nav__list__item">
              <a href="https://ahazou.com/#plans" title="Ir para a sessão de planos e preços da página" className="footer__nav__list__item__anchor">
                <Typography element="span" variant="buttonS">planos e preços</Typography>
              </a>
            </li>
            <li className="footer__nav__list__item">
              <a href="https://descubra.ahazou.com/" title="Posts e Frases Prontas! - Descubra Ahazou" className="footer__nav__list__item__anchor">
                <Typography element="span" variant="buttonS">veja mais conteúdos</Typography>
              </a>
            </li>
            {/* <li className="footer__nav__list__item">
              <a href="http://meajuda.ahazou.com/pt-BR/" title="Tire suas dúvias no nosso FAQ" className="footer__nav__list__item__anchor">
                <Typography element="span" variant="buttonS">perguntas frequentes</Typography>
              </a>
            </li> */}
            <li className="footer__nav__list__item">
              <a href="/terms-of-use" title="Leia nossos Termos de Uso" className="footer__nav__list__item__anchor">
                <Typography element="span" variant="buttonS">termos de uso</Typography>
              </a>
            </li>
            <li className="footer__nav__list__item">
              <a href="/privacy-policy" title="Leia nossa Política de Privacidade" className="footer__nav__list__item__anchor">
                <Typography element="span" variant="buttonS">política de privacidade</Typography>
              </a>
            </li>
          </ul>

          <ul className="footer__nav__list footer__nav__second_column">
            <li className="footer__nav__list__item">
              <Typography variant="displayS" className="footer__nav__list__item__title">a empresa</Typography>
            </li>
            <li className="footer__nav__list__item">
              <a href="https://ahazou.com/quem-somos/" title="Quer saber um pouco mais sobre nós?" className="footer__nav__list__item__anchor">
                <Typography element="span" variant="buttonS">quem somos</Typography>
              </a>
            </li>
            <li className="footer__nav__list__item">
              <a href="https://blog.ahazou.com/" title="Veja nossos posts no blog" className="footer__nav__list__item__anchor">
                <Typography element="span" variant="buttonS">blog</Typography>
              </a>
            </li>
          </ul>

          {isIOS && (
            <ul className="footer__nav__list footer__nav__download">
              <li className="footer__nav__list__item">
                <Typography variant="displayS" className="footer__nav__list__item__title">baixe nosso app</Typography>
              </li>
              <li className="footer__nav__list__item">
                <StoreBadge />
              </li>
            </ul>
          )}

          <div className="footer__nav__third_column">
            <ul className="footer__nav__list">
              <li className="footer__nav__list__item">
                <Typography variant="displayS" className="footer__nav__list__item__title">siga nossas redes sociais</Typography>
              </li>
              <li className="footer__nav__list__item__icon">
                <a href="https://www.instagram.com/ahazouapp/" title="Ahazou Instagram">
                  <Icon
                    icon="graph_instagram"
                    className="footer__nav__list__item__icon__element"
                  />
                </a>
              </li>
              <li className="footer__nav__list__item__icon">
                <a href="https://www.facebook.com/ahazouapp/" title="Ahazou Facebook">
                  <Icon
                    icon="graph_facebook"
                    className="footer__nav__list__item__icon__element"
                  />
                </a>
              </li>
              {/* <li className="footer__nav__list__item__icon">
                <a href="https://br.pinterest.com/ahazouapp/" title="Ahazou Pinterest">
                  <Icon
                    icon="graph_pinterest"
                    className="footer__nav__list__item__icon__element"
                  />
                </a>
              </li> */}
              <li className="footer__nav__list__item__icon">
                <a href="https://www.youtube.com/@CanalAhazou" title="Ahazou Youtube">
                  <Icon
                     iconPath={youtubeIcon} 
                    className="footer__nav__list__item__icon__element"
                  />
                </a>
              </li>
              <li className="footer__nav__list__item__icon">
                <a href="https://www.tiktok.com/@ahazouoficial" title="Ahazou Tiktok">
                  <Icon
                     iconPath={tiktokIcon} 
                    className="footer__nav__list__item__icon__element"
                  />
                </a>
              </li>
              <li className="footer__nav__list__item__icon">
                <a href="https://br.linkedin.com/company/ahazouapp" title="Ahazou LinkedIn">
                  <Icon
                    icon="graph_linkedin"
                    className="footer__nav__list__item__icon__element"
                  />
                </a>
              </li>
            </ul>

            <ul className="footer__nav__list">
              <li className="footer__nav__list__item">
                <Typography variant="displayS" className="footer__nav__list__item__title">ficamos no Google Campus for Startups</Typography>
              </li>
              <li className="footer__nav__list__item footer__nav__list__item--flex">
                <Icon
                  size="l"
                  icon="mono_location"
                  className="footer__nav__list__item__icon--location"
                />
                <div className="footer__nav__list__item--address">
                  <Typography element="span" variant="buttonS">rua coronel oscar porto, 70 - 2º andar</Typography>
                  <Typography element="span" variant="buttonS">paraíso, são paulo - sp</Typography>
                </div>
              </li>
            </ul>
          </div>

        </nav>
      </Container>
    </footer>
  )
}

export default Footer;