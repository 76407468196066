import React from "react";
import classNames from "classnames";
import apple_badge from "../../images/ahazou-badge-apple.svg";
import android_badge from "../../images/webp/ahazou-badge-google_resized.webp";
import { isAndroid, isIOS } from "react-device-detect";
import "./style.css";

const AndroidBadge = ({ className }) => (
  <a
    className={classNames("badgeLink", className)}
    href="https://ahazou.app.goo.gl/site-ahz"
  >
    <img
      height={80}
      width={268}
      className="badge__img"
      alt="Ahazou App - Disponível no Google Play"
      src={android_badge}
    />
  </a>
);

const IOSBadge = ({ className }) => (
  <a
    className={classNames("badgeLink", className)}
    href="https://ahazou.app.goo.gl/site-ahz"
  >
    <img
      height={80}
      width={268}
      className="badge__img"
      alt="Ahazou App - Disponível na App Store"
      src={apple_badge}
    />
  </a>
);

const StoreBadge = ({ className }) => {
  if (isAndroid) {
    return <AndroidBadge className={className} />;
  }

  if (isIOS) {
    return <IOSBadge className={className} />;
  }

  return (
    <>
      <div className={classNames("badge", className)}>
        <AndroidBadge className="badges__android" />
        <IOSBadge />
      </div>
    </>
  );
};

export default StoreBadge;
