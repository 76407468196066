import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { NavHeader as AhzNavHeader, NavItem as AhzNavItem } from '@ahazou/react-components'
import StoreBadge from '../StoreBadge'
import './style.css'

const links = [
  {
    url: '/#inicio',
    icon: 'mono_idea',
    label: 'início',
  },
  {
    url: '/#planos',
    icon: 'mono_arrow_down',
    label: 'planos e preços',
  },
  {
    url: 'http://descubra.ahazou.com/',
    icon: 'mono_post_collection',
    label: 'veja posts prontos',
  },
  {
    url: 'https://blog.ahazou.com/',
    icon: 'mono_post_single',
    label: 'blog',
  },
  {
    url: 'http://meajuda.ahazou.com/',
    icon: 'mono_help',
    label: 'perguntas frequentes',
  },
]

const NavItem = ({ url, closeNavHeader, ...rest }) => {
  const navItemProps = rest

  if (url.indexOf('http') !== -1) {
    return <AhzNavItem {...navItemProps} target="_blank" url={url} rel="noopener noreferrer" />
  }

  return <AhzNavItem {...navItemProps} url={url} />
}

const HomeLink = ({ children, className, url }) => (
  <a href={url} as={url} className={classNames(className, 'nav-header__home-link')}>
    {children}
  </a>
)

HomeLink.defaultProps = {
  className: null,
  url: '/',
}

const NavHeader = ({ routePath, onDownloadClick }) => {
  const [navOpened, setNavOpened] = useState(false)
  const [navHide, setNavHide] = useState(false)
  const [navTransparent, setNavTransparent] = useState(true)
  const [isFixed, setIsFixed] = useState(false)

  const switchNav = () => setNavOpened(!navOpened)

  const handleScroll = () => {
    const scrollYLimit = 150

    if (window.scrollY >= scrollYLimit && navTransparent) {
      setNavHide(false)
      setIsFixed(false)
      setNavHide(true)
      // delay for wait css transition only
      window.setTimeout(() => {
        setNavTransparent(false)
        setIsFixed(true)
        setNavHide(false)
      }, 10)
    } else if (window.scrollY < scrollYLimit && navTransparent === false) {
      setNavHide(false)
      setIsFixed(false)
      setNavHide(true)
      // delay for wait css transition only
      window.setTimeout(() => {
        setNavTransparent(true)
        setIsFixed(false)
        setNavHide(false)
      }, 200)
    }
  }

  useEffect(() => {
    /**
     * Call `handleScroll` to initialize `navTransparent`
     * correctly if user are in middle of page and reload the page.
     */
    handleScroll()

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  const closeNavHeader = () => {
    if (isMobile && navOpened) {
      switchNav()
    }
  }

  return (
    <AhzNavHeader
      navOpened={navOpened}
      logoContainer={HomeLink}
      transparent={navTransparent}
      onNavClick={switchNav}
      openFrom="top"
      className={classNames('nav-header', {
        'nav-header--hide': navHide,
        'nav-header--absolute': !isFixed,
        'nav-header--fixed': isFixed,
        'nav-header--opened': navOpened
      })}
    >
      {links.map((link) => (
        <NavItem
          key={link.label}
          {...link}
          transparent={navTransparent}
          closeNavHeader={closeNavHeader}
          active={link.url === routePath}
          className="nav__item"
          onClick={switchNav}
        />
      ))}

      <AhzNavItem
        url="https://ahazou.app.goo.gl/site-ahz"
        icon="mono_arrow_down"
        label="baixe o app Ahazou"
        onClick={() => {
          switchNav()
          onDownloadClick()
        }}
      />

      {/* <li className="nav-header__store-badge">
        <StoreBadge />
      </li> */}
    </AhzNavHeader>
  )
}

export default NavHeader