import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Typography } from '@ahazou/react-components'
import StoreBadge from '../StoreBadge'
import baixeGratisImg from '../../images/webp/ahazou-modal-baixe-gratis@3_11zon.webp'
import './style.css'

const ModalDeviceStore = ({ opened, onClose }) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      className="baixe-gratis__modal"
      wrapperClassName="baixe-gratis__modal-wrapper"
      contentClassName="baixe-gratis__modal-content"
      closeButtonClassName="baixe-gratis__modal-button"
    >
      <Typography element="h3" variant="displayL" className="baixe-gratis__modal-title">
        baixe o app Ahazou
      </Typography>

      <StoreBadge
        className="baixe-gratis__modal-badges"
        tracker={{ category: 'Baixe Gratis', action: 'click (modal)' }}
      />

      <figure className="baixe-gratis__modal-image-wrapper">
        <img
          src={baixeGratisImg}
          alt="baixe o app Ahazou"
          className="baixe-gratis__modal-image"
        />
      </figure>

      <span className="baixe-gratis__modal-bg-blue-dot" />
    </Modal>
  )
}

ModalDeviceStore.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ModalDeviceStore
